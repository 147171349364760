<app-header></app-header>

<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
  <div class="carousel-inner" style="height : auto;">
    <div class="carousel-item active">
      <img class="d-block w-100" src="/assets/slider.JPG" alt="First slide">

    </div>

    <div class="carousel-item">
      <img class="d-block w-100" src="/assets/slider.JPG" alt="Second slide">

    </div>
    <div class="carousel-item">
      <img class="d-block w-100" src="/assets/slider.JPG" alt="Third slide">

    </div>

  </div>
  <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="sr-only">Previous</span>
  </a>
  <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="sr-only">Next</span>
  </a>
</div>


<div class="container mt-5 mb-5">
  <div class="row">
    <div class="col-md-3 col-lg-3 col-xs-12 col-sm-12 p-0">
      <div class="vertical-menu" style="border-top: 4px solid #ED1B24;">
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Software</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Hardware</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Buchsotichment</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Root</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">IT Hardware</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">IT Software</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Mobiles</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Laptops</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Root</b></a>
        <a (click)="routingCall()" class="bannerMenu cursorpointer"><b style="padding-left: 25px;">Music</b></a>
        <a (click)="routingCall()" style="color: #ED1B24;"><b style="padding-left: 25px; cursor: pointer;">View All&nbsp;<i class="fa fa-chevron-right"
              aria-hidden="true"></i></b></a>

      </div>
      <div class="mt-2" style="background-color: #ED1B24; height: 225px; border-radius: 10px;">
        <h1 class="pt-4 mx-3" style="color: whitesmoke;"><i class="fas fa-quote-left"></i></h1>
        <p class="px-3" style="color: whitesmoke; font-size: 14px;">Through thid step the distributor
          is expanding its position in the growing Swiss lightening market.</p>
        <h5 class="mx-3" style="color: whitesmoke;">LARA DUPP</h5>
      </div>
    </div>
    <div class="col-md-9 col-lg-9 col-xs-12 col-sm-12">
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/led.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/headphone.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/speaker.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/mic.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/ringLight.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/laptop.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/dslr.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
        <div class="col-md-3 col-lg-3 col-xs-12 col-sm-6 border text-center pb-5">
          <img src="/assets/items/camera.png" class="items" />
          <div class="mt-3">
            <b>Fantasy 55S UHD Black</b><br>
            <p class="starClrs mt-2">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="row mt-4" style="row-gap: 10px;">
    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 border wallpaper1 p-0">

    </div>
    <div class="col-md-6 col-lg-6 col-sm-12 col-xs-12 border wallpaper2 p-0">

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-md-3 col-lg-3 col-xs-12 col-sm-12">
      <div class="row">
        <div class="col bestSaleDiv">
          <h4 class="pt-1">BEST SALES</h4>
        </div>

      </div>
      <div class="row">
        <div class="col border text-center pb-5">
          <img src="/assets/items/iphone-12-pro-max-family-hero-all-removebg-preview.png" class="itemsMobile" />
          <div class="mt-3">
            <b>Mobile Phones</b><br>
            <p class="starClrs mt-4">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>
        </div>
      </div>
      <div class="row border">
        <div class="col d-flex justify-content-around">
          <img src="/assets/items/m1.png" (click)="routingCall()" class="itemsMobileSmall border p-2" />
          <img src="/assets/items/m2.png" (click)="routingCall()" class="itemsMobileSmall border p-2" />
          <img src="/assets/items/m3.png" (click)="routingCall()" class="itemsMobileSmall border p-2" />
        </div>
      </div>

    </div>
    <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 border">
      <div class="row">
        <div class="col bestSaleDiv">
          <h4 class="pt-1">NEW ARRIVALS</h4>
        </div>
      </div>
      <div class="row">
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m4.png" class="newArrivalsItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m5.png" class="newArrivalsItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>

      </div>
      <div class="row mt-3">
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m6.png" class="newArrivalsItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m7.png" class="newArrivalsItems" />
          <div class="mt-3">
            <b>AirPods 2 Gen.</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>

      </div>
      <div class="row mt-5 ">
           <div class="col text-center saleDiv mx-3">

           </div>


      </div>
    </div>
    <div class="col-md-3 col-lg-3 col-xs-12 col-sm-12 pb-3 border">
      <div class="row">
        <div class="col saleOffDiv">
          <h4 class="pt-1">SALE OFF</h4>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m5.png" class="saleOffItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>
      </div>
      <div class="row pt-3">
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m4.png" class="saleOffItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>
      </div>
      <div class="row pt-3">
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m4.png" class="saleOffItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>
      </div>
      <div class="row pt-3">
        <div class="col d-flex justify-content-around ">
          <img src="/assets/items/m4.png" class="saleOffItems" />
          <div class="mt-3">
            <b>Iphone 12 Pro Max</b><br>
            <p class="starClrs1 mt-1">
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>
              <i class="fas fa-star"></i>&nbsp;
              <span style="color: gray;">(0)</span>
            </p>
            <small class="priceDiv" (click)="routingCall()">CHF 65.500</small>
          </div>

        </div>
      </div>
    </div>

  </div>


<div class="row mt-5 border-bottom">
        <div class="col-md-3 col-lg-3 col-sm-6 col-xs-12" style=" border-bottom: 3px solid #ED1B24;">
              <h2 style="font-weight: 600;">Our Clients    </h2>
        </div>
</div>
<div class="row mt-5">
  <div class="col text-center">
    <div class="border p-5"><img src="/assets/dell.png" class="partners"/></div>
  </div>
  <div class="col text-center">
    <div class="border p-5"><img src="/assets/panasonic.png" class="partners"/></div>
</div>
<div class="col text-center">
  <div class="border p-5"><img src="/assets/cannon.png" class="partners"/></div>
</div>
<div class="col text-center">
  <div class="border p-5"><img src="/assets/hp.png" class="partners"/></div>
</div>
<div class="col text-center">
  <div class="border p-5"><img src="/assets/sony.png" class="partners"/></div>
</div>


</div>

<div class="row mt-4">
      <div>

      </div>
</div>

</div>

<div class="container-fluid p-0" style="overflow: hidden;">
  <div class="row mt-4">
    <div class="col sale20">
            <div class="container mt-3 d-flex justify-content-around">
                 <img src="/assets/devices.png" class="sale20Img"/>
                 <h1 class="sale20Text">20% Off On<h6>All Electronics Devices</h6></h1>
                 <button class="btn sale20Btn">Shop Now</button>
            </div>
    </div>
</div>
</div>

<app-footer></app-footer>
