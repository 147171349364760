<div class="backgroundImg">

  <div class="wrapper">
    <div class="d-flex justify-content-between mt-5">
      <img src="/assets/sorry.png" class="imageSoon"/>
      <h2 class="fontSize">Diese Seite befindet sich im Umbau. Wir danken Ihnen für Ihre Geduld</h2>
    </div>

    <h1 class="text-center" style="color: whitesmoke;">We Are Coming Soon</h1>
    <div class="icons mt-5">
      <a href="#" class="icons mx-2 "><i class="fab fa-facebook"></i></a>
      <a href="#" class="icons mx-2"><i class="fab fa-linkedin"></i></a>
      <a href="#" class="icons mx-2"><i class="fab fa-instagram"></i></a>
      <a href="#" class="icons mx-2"><i class="fab fa-twitter-square"></i></a>
      <a href="#" class="icons mx-2"><i class="fab fa-pinterest-square"></i></a>
    </div>
   </div>

  </div>

