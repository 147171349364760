<div class="container-fluid p-0" style="overflow: hidden;">
     <div class="row">
           <div class="col" style="background-color: #ED1B24; height: 8px;">

           </div>
     </div>
</div>
<nav class="navbar navbar-expand-md p-0" >
  <div class="navbar-collapse collapse w-100 order-1 order-md-0 dual-collapse2  border-bottom">
    <div class="container">
      <ul class="navbar-nav mr-auto d-flex justify-content-between px-3 ">
        <li class="nav-item dropdown topBarFont border-left border-right py-2">
          <a class="nav-link dropdown-toggle px-3" style="color: gray;" (click)="routingCall()" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            ENGLISH
          </a>
          <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div> -->
        </li>
        <li class="nav-item dropdown topBarFont  border-right py-2">
          <a class="nav-link dropdown-toggle px-4" style="color:gray;"  (click)="routingCall()" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            USD
          </a>
          <!-- <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a>
          </div> -->
        </li>
        <!-- <li class="nav-item" >
           <small>ORDER ONLINE OR CALL US 8 800 7777-23-22</small>
        </li> -->

    </ul>
    </div>

  </div>
  <div class="order-0 centerAlignTopBrand">
    <small class="navbar-brand topBarBrandStyle">ORDER ONLINE OR CALL US x xxx xxxx-xx-xx</small>

  </div>
  <div class="navbar-collapse collapse w-100 order-3 dual-collapse2 border-bottom">
     <div class="container">
      <ul class="navbar-nav ml-auto px-3">

        <li class="nav-item topBarFont border-left border-right py-2" >
          <a class="nav-link px-3"  (click)="routingCall()" style="color:gray;"><i class="fas fa-lock-open"></i>&nbsp; Login</a>
      </li>
        <li class="nav-item topBarFont border-right py-2">
            <a class="nav-link px-3"  (click)="routingCall()" style="color:gray;"><i class="fas fa-key"></i>&nbsp; Register</a>
        </li>
        <li class="nav-item topBarFont border-right py-2">
          <a class="nav-link px-3"  (click)="routingCall()" style="color: gray;"><i class="fa fa-heart" aria-hidden="true"></i>&nbsp;&nbsp; Wishlist</a>
      </li>
      <li class="nav-item topBarFont border-right py-2" >
        <a class="nav-link px-3"  (click)="routingCall()"  style="color: gray;"><i class="fa fa-search" aria-hidden="true"></i></a>
    </li>
    </ul>
     </div>

  </div>
</nav>

<div id="app" class="container-fluid p-0 ">
  <div class="mx-5">
    <nav class="navbar navbar-expand-lg ">
      <a class="navbar-brand" href="#"><img class="logo" src="/assets/logo.JPG"/></a>
      <button class="navbar-toggler" type="button" data-toggle="modal" data-target="#sidebarModal" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <i class="fas fa-bars"></i>
      </button>
      <div id="navbarNavDropdown" class="navbar-collapse collapse">
          <ul class="navbar-nav mr-auto">
              <!-- <li class="nav-item active">
                  <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#">Features</a>
              </li>
              <li class="nav-item">
                  <a class="nav-link" href="#">Pricing</a>
              </li> -->

          </ul>
          <ul class="navbar-nav">

              <li class="nav-item px-2 ">
                  <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">Offers</a>
              </li>
              <li class="nav-item px-2 ">
                  <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">New Arrivals</a>
              </li>
              <li class="nav-item px-2 ">
                <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">About us</a>
            </li>
            <li class="nav-item px-2 ">
                <a class="nav-link mainMenu cursorpointer" (click)="routingCall()">Products</a>
            </li>
            <li class="nav-item px-2 ">
              <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">News</a>
          </li>
          <li class="nav-item px-2 ">
              <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">Contact</a>
          </li>
          <li class="nav-item px-2 ">
            <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">Terms</a>
        </li>
        <li class="nav-item px-2 ">
            <a class="nav-link mainMenu cursorpointer"  (click)="routingCall()">Account</a>
        </li>

      <li class="nav-item px-2 ">
        <a class="nav-link mainMenu"  (click)="routingCall()"><i class="fas fa-shopping-cart"></i></a>
    </li>
          </ul>
      </div>
  </nav>
  </div>

</div>
<div class="container-fluid">
  <div class="modal left fade menu" id="sidebarModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog position-fixed m-auto" role="document">
          <div class="modal-content h-100">
            <div class="input-group">
              <input type="text" class="form-control" placeholder="Search">
              <div class="input-group-append">
                <button class="btn" style="background-color: #ED1B24; color: white;" type="button">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="d-flex justify-content-around">
              <a class="nav-link header_items" href="#" style="color:  #ED1B24;"><i class="fas fa-shopping-cart"></i></a>
              <a class="nav-link header_items" href="#" style="color:   #ED1B24"><i class="fa fa-heart" aria-hidden="true"></i></a>
              <a class="nav-link dropdown-toggle px-3" style="color:  #ED1B24; font-size: 12px;" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <b>ENGLISH</b>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
              <a class="nav-link dropdown-toggle px-3" style="color: #ED1B24; font-size: 12px;" href="http://example.com" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
               <b> USD / $</b>
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </div>

              <div class="modal-body p-3">
                  <div class="nav flex-column">
                      <a class="nav-item nav-link sidebarMenu" data-dismiss="modal" (click)="routingCall()">Offers</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >New Arrivals</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >About Us</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >Products</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >News</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >Contact</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >Terms</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >Account</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >Login</a>
                      <a (click)="routingCall()" data-dismiss="modal" class="nav-item nav-link sidebarMenu" >Logout</a>
                  </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn" style="background-color:  #ED1B24; color: white;" data-dismiss="modal">Close</button>
              </div>
          </div>
      </div>
  </div>
</div>
